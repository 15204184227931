<template>
  <div>
    <AppBar :isApprover="isApproval" :path="path" />
    <v-container class="mt-n3">
      <v-card class="mx-auto">
        <div class="d-flex justify-end">
          <div class="mx-2 my-2">
            <v-btn
              color="red"
              text
              class="btnActions"
              style="background-color: #ffcdd2"
              link
              to="/leave/application"
            >
              <v-icon>mdi-close</v-icon>
              close
            </v-btn>
          </div>
        </div>
        <v-divider></v-divider>
        <v-container v-if="loadingPreview">
          <v-progress-circular
            style="margin-left: 47%; margin-top: 30%"
            indeterminate
            width="2"
            size="65"
            color="primary"
          ></v-progress-circular>
        </v-container>
        <div v-if="!loadingPreview">
          <v-container class="container--width">
            <v-form
              ref="applicationForm"
              @submit.prevent="submitApplication"
              v-model="isValid"
            >
              <v-stepper
                class="formStepper"
                v-model="step"
                alt-labels
                elevation="0"
                vertical
              >
                <v-stepper-header>
                  <v-stepper-step
                    :complete="step > 1"
                    :color="
                      leaveTypeValid !== 'error'
                        ? 'green accent-2'
                        : 'red accent-2'
                    "
                    :step="leaveTypeValid !== 'error' ? '1' : '!'"
                    class="formStep"
                  >
                    Leave Type
                  </v-stepper-step>

                  <v-divider class="stepDivider"></v-divider>

                  <v-stepper-step
                    :complete="step > 2"
                    :color="
                      leaveValid !== 'error' ? 'green accent-2' : 'red accent-2'
                    "
                    :step="leaveValid !== 'error' ? '2' : '!'"
                    class="formStep"
                  >
                    Leave Duration
                  </v-stepper-step>

                  <v-divider class="stepDivider"></v-divider>

                  <v-stepper-step
                    :complete="step > 3"
                    :color="
                      attachmentMandatory !== 'error'
                        ? 'green accent-2'
                        : 'red accent-2'
                    "
                    :step="attachmentMandatory !== 'error' ? '3' : '!'"
                    class="formStep"
                  >
                    Hand Over To
                  </v-stepper-step>

                  <v-divider class="stepDivider"></v-divider>

                  <v-stepper-step
                    :complete="step > 4"
                    color="green accent-2"
                    step="4"
                    class="formStep"
                  >
                    Save Application
                  </v-stepper-step>
                </v-stepper-header>
                <div>
                  <v-stepper-items class="stepperItems">
                    <LeaveDuration
                      v-if="step !== 1 && step !== 4"
                      class="mt-2"
                      style="width: 100%"
                      v-show="leaveValidationData.status === 'success'"
                      :data="leaveDurationData"
                    />
                    <div>
                      <v-stepper-content step="1">
                        <v-container class="stepper-body-width">
                          <LeaveType
                            :leaveTypes="leaveTypes"
                            :leaveReasons="leaveReasons"
                            :ReasonCode="ReasonCode"
                            :ReasonDescription="ReasonDescription"
                            @updateReason="updateReason"
                            @updateLeaveReasonDesc="updateLeaveReasonDesc"
                            @updateLeaveType="updateLeaveType"
                            :rules="[rules.common.required]"
                            :displayLeaveType="leave_type"
                          />
                        </v-container>
                      </v-stepper-content>
                    </div>

                    <div>
                      <v-stepper-content step="2">
                        <v-container class="stepper-body-width">
                          <div v-show="loadingv" class="my-10">
                            <v-progress-circular
                              style="margin-left: 47%; margin-top: 4%"
                              indeterminate
                              width="2"
                              size="50"
                              color="primary"
                            ></v-progress-circular>
                          </div>
                          <div v-show="!loadingv">
                            <StartDate
                              v-model="startDate"
                              :leavePeriod="leavePeriod"
                              :rules="[rules.common.required]"
                              :displayDate="startDate"
                              :leaveTypes="leaveTypes"
                              :leave_type="leave_type"
                            />
                            <v-btn-toggle
                              v-model="selectedType"
                              borderless
                              dense
                              mandatory
                              color="primary"
                              class="toggleBtn mt-n2 mb-2"
                            >
                              <v-btn value="left" width="50%">
                                <v-icon left> view_week </v-icon>
                                <span class="caption"
                                  >Enter Number of days</span
                                >
                              </v-btn>
                              <v-btn value="right" width="50%">
                                <span class="caption">Enter End date</span>
                                <v-icon right> mdi-calendar </v-icon>
                              </v-btn>
                            </v-btn-toggle>
                            <div v-if="selectedType === 'left'">
                              <v-text-field
                                v-model="no_days"
                                label="Number Of Days"
                                type="number"
                                :min="1"
                                :max="leavePreset"
                                class="mt-1 leaveInputs"
                                filled
                                :rules="[rules.common.required]"
                              >
                                <template v-slot:prepend-inner>
                                  <v-icon color="primary"> view_week </v-icon>
                                </template>
                              </v-text-field>
                            </div>
                            <div v-else>
                              <EndDate
                                v-model="endDate"
                                :rules="[rules.common.required]"
                                :leavePeriod="leavePeriod"
                                :startDate="startDate"
                                :displayDate="endDate"
                              />
                            </div>
                          </div>
                        </v-container>
                      </v-stepper-content>
                    </div>

                    <div>
                      <v-stepper-content step="3">
                        <v-container class="stepper-body-width">
                          <v-row>
                            <v-col cols="12" md="12">
                              <HandoverForm
                                :leaveEmployees="leaveEmployees"
                                :displayHandover="hand_over"
                                :handoverRequired="handoverRequired"
                                @handoverUpdated="handoverUpdated"
                                :rules="[rules.common.required]"
                              />
                            </v-col>
                            <v-col cols="12" md="12" class="mt-n7">
                              <div class="label mx-4">Comments</div>
                              <v-textarea
                                placeholder="Comments"
                                :rows="1"
                                v-model="comments"
                                clearable
                                class="mt-1 leaveInputs"
                                filled
                              >
                                <template v-slot:prepend-inner>
                                  <v-icon color="primary"> comment </v-icon>
                                </template>
                              </v-textarea>
                            </v-col>
                          </v-row>
                          <div>
                            <v-btn
                              v-show="!addAttachment"
                              outlined
                              block
                              color="primary"
                              @click="addAttachment = !addAttachment"
                            >
                              <v-icon class="mx-3">add</v-icon>
                              Add attachment
                            </v-btn>
                          </div>
                          <v-file-input
                            @change="saveAttachment"
                            color="primary"
                            label="Add Image File Attachment"
                            class="mt-1 leaveInputs"
                            prepend-inner-icon="mdi-image"
                            filled
                            dense
                            v-show="addAttachment"
                            accept="*"
                            :show-size="1000"
                            :rules="rules.file"
                          >
                            <template v-slot:selection="{ text }">
                              <v-chip outlined label small>
                                {{ text }}
                              </v-chip>
                            </template>
                          </v-file-input>
                        </v-container>
                      </v-stepper-content>
                    </div>
                    <div>
                      <v-stepper-content step="4">
                        <v-alert
                          prominent
                          type="info"
                          icon="assignment_turned_in"
                        >
                          Thanks for filling out the application form. click on
                          the {{ isEdit ? "Edit" : "Save" }} button to finish.
                        </v-alert>
                      </v-stepper-content>
                    </div>
                  </v-stepper-items>
                </div>
              </v-stepper>
            </v-form>
          </v-container>
          <v-card-actions>
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="12" md="6">
                  <v-btn
                    elevation="4"
                    block
                    class="btnActions"
                    @click="step -= 1"
                  >
                    <v-icon class="mx-2">arrow_back</v-icon>
                    Go back
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <div v-if="step !== 4">
                    <v-btn
                      color="primary"
                      elevation="4"
                      block
                      :disabled="startDate.length === 0"
                      class="btnActions"
                      @click="validateStep(step)"
                    >
                      continue
                      <v-icon class="mx-2">arrow_forward</v-icon>
                    </v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      color="primary"
                      block
                      class="btnActions"
                      style="color: #fff"
                      elevation="2"
                      @click="submitApplication"
                    >
                      {{ isEdit ? "Edit" : "Save" }}
                      <v-icon class="mx-3">save</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="4"></v-col>
          </v-card-actions>
        </div>
      </v-card>
      <div class="my-10"></div>
      <confirmationdialog
        :leaveData="leaveData"
        :dialog="dialog"
        v-on:close-dialog="closeDialog"
      />

      <div class="text-center"></div>
    </v-container>
    <app-alert />
  </div>
</template>
<style>
@import "../styles.css";
</style>
<script>
/* eslint-disable camelcase */
import confirmationdialog from "./Confirmation.vue";
import LeaveType from "../components/LeaveType";
import StartDate from "../components/StartDate";
import EndDate from "../components/EndDate";
import HandoverForm from "../../handover/components/HandoverForm";
import LeaveDuration from "../components/LeaveDuration";
import { SetupMixin } from "../../../mixins";
import AppBar from "../components/AppBar";
import LeaveMethods from "../LeaveMethods";
import appAlert from "@/packages/cassandra-base/plugins/appAlert/appAlert.vue";
// import helpers from '@kinetics254/cassandra-base/utilities/helpers/helper'
export default {
  name: "make_application",
  components: {
    LeaveType,
    StartDate,
    EndDate,
    HandoverForm,
    LeaveDuration,
    AppBar,
    confirmationdialog,
    appAlert,
  },
  mixins: [SetupMixin],
  data: function () {
    return {
      isApproval: this.$router.currentRoute.meta.isApprover,
      path: this.$router.currentRoute.meta.breadcrumb,
      step: 1,
      startDate: new Date().toISOString().substr(0, 10),
      availableDates: [],
      no_days: null,
      return_date: "",
      comments: "",
      leave_type: "",
      ReasonCode: "",
      ReasonDescription: "",
      leavePreset: 0,
      daysRemaining: 0,
      hand_over: "",
      isValid: false,
      isEdit: null,
      selectedType: null,
      endDate: "",
      leaveValid: "",
      leaveDurationData: {},
      editApplicationCode: "",
      attachment: null,
      attachmentName: "",
      addAttachment: false,
      leaveTypeValid: "",
      attachmentMandatory: "",
      dialog: false,
      allowancedialog: false,
      handoverData: {},
      company: process.env.VUE_APP_COMPANY,
      rules: {
        common: {
          required: (v) => !!v || "Field is required.",
        },
        file: [
          (value) =>
            !value ||
            value.size < 2000000 ||
            "Image size should be less than 2 MB!",
        ],
      },
    };
  },
  beforeRouteEnter(to, from, next) {
    next((v) => {
      if (to.params.application_code !== undefined) {
        v.getApplication(to.params);
      }
      if (v.isAllowance) {
        v.$store.dispatch("allowance/CheckIfAppliedLeaveAllowance");
      }
      v.getSetup();
      v.$store.dispatch("handover/getHandoverReasons");
    });
  },
  watch: {
    application() {
      const {
        application_code,
        start_date,
        end_date,
        comments,
        hand_over_to,
        leave_code,
        days_applied,
      } = this.application;
      this.isEdit = true;
      this.editApplicationCode = application_code;
      this.startDate = start_date;
      this.endDate = end_date;
      this.comments = comments;
      this.hand_over = hand_over_to;
      this.leave_type = leave_code;
      this.no_days = days_applied;
      console.log(this.application);
    },
    endDate() {
      if (this.endDate.length !== 0) {
        this.selectedType = "right";
      }
    },
    $route(to, from) {
      this.isApproval = to.meta.isApprover;
      this.path = to.meta.breadcrumb;
    },
    leaveValidationData() {
      if (
        this.leaveValidationData.show &&
        this.leaveValidationData.status === "success"
      ) {
        this.step += 1;
        this.leaveValid = this.leaveValidationData.status;
        const data = this.leaveValidationData.data;
        this.return_date = data.return_dates;
        this.endDate = data.end_date;
        this.no_days = data.leave_days;
        this.leaveDurationData = {
          start_date: this.startDate,
          return_date: this.return_date,
          end_date: this.endDate,
          days_applied: this.no_days,
          status: data.status,
        };
      } else {
        this.leaveValid = this.leaveValidationData.status;
      }
    },
    leave_type() {
      if (this.leave_type.length !== 0) {
        this.leavePreset = this.leaveTypes.filter(
          (item) => item.code === this.leave_type
        )[0].Days;
        this.no_days = 1;
      } else {
        this.step = 1;
      }
    },
  },
  computed: {
    data: function () {
      return this.$store.getters["leave/leaveGetter"]("previewData");
    },
    loadingPreview: function () {
      return this.$store.getters["leave/leaveGetter"]("loadingPreview");
    },
    loadingv: function () {
      return this.$store.getters["leave/leaveGetter"]("loadingv");
    },
    leaveValidationData: function () {
      return this.$store.getters["leave/leaveGetter"]("leaveValidationData");
    },
    appDialog: function () {
      return this.$store.getters["leave/leaveGetter"]("appDialog");
    },
    userData: function () {
      return this.$store.getters["auth/user"];
    },
    leaveDescription: function () {
      let leaveData = [];
      if (this.leave_type.length !== 0) {
        leaveData = this.leaveTypes.filter(
          (item) => item.code === this.leave_type
        )[0];
      }
      return leaveData.Description;
    },
    application: function () {
      return this.$store.getters["leave/leaveGetter"]("application");
    },
    Accrueddays: function () {
      return this.allocations
        ? this.allocations.filter((i) => i.leave_code === this.leave_type)[0]
        : {};
    },
    CalculatAccruedPercentage: function () {
      return Math.round(
        (this.Accrueddays.accrued_days / this.Accrueddays.allocated_days) * 100
      );
    },
    isAllowance: function () {
      const links = this.$store.getters["Dashboard/links"].filter(
        (el) => el.to
      );
      return links.find((el) => /leaveallowance/.test(el.to));
    },
    leaveAllowancePermission() {
      return this.$store.getters["allowance/allowanceGetters"](
        "leaveAllowancePermission"
      );
    },
    leaveData: function () {
      const {
        startDate,
        no_days,
        comments,
        hand_over,
        leave_type,
        endDate,
        userData,
        attachment,
        ReasonCode,
        ReasonDescription,
      } = this;
      return {
        comments: comments,
        days_applied: no_days,
        employee_no: userData.employee,
        end_date: endDate,
        hand_over_to: hand_over,
        leave_code: leave_type,
        start_date: startDate,
        attachment,
        ReasonCode,
        ReasonDescription,
      };
    },
    handoverRequired: function () {
      return this.leaveTypesAll && this.leave_type !== ""
        ? this.leaveTypesAll.find((d) => d.code === this.leave_type)
            .requireHandover
        : false;
    },
  },
  methods: {
    ...LeaveMethods,
    closeDialog: function (val) {
      this.dialog = val;
    },
    handoverUpdated: function (val) {
      console.log("handover app", val);
      this.handoverData = val;
    },
    updateReason: function (val) {
      this.ReasonCode = val;
    },
    updateLeaveType: function (val) {
      this.leave_type = val;
    },
    updateLeaveReasonDesc: function (val) {
      this.ReasonDescription = val;
    },
  },
};
</script>
